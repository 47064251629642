import React, { FunctionComponent } from "react"
import { AuroraDeliveryListDownLoad } from "./AuroraDelivery/auroraDeliveryDownload"

export const AuroraDeliveryPage: FunctionComponent = () => {
  return (
    <>
      <AuroraDeliveryListDownLoad />
    </>
  )
}
