import firebase from "gatsby-plugin-firebase"
import { useEffect, useState } from "react"

export const useFirestore = () => {
  const [ firestore, setFirestore ] = useState<firebase.firestore.Firestore | undefined>(undefined)

  useEffect(() => {
    const temp = firebase.firestore()
    temp.settings({ ignoreUndefinedProperties: true })
    setFirestore(temp)
  }, [ firebase ])

  return firestore
}

/**
 * 表示時間
 */
export const DISPLAY_TIME_AURORA = {
  "00:00": 0,    "00:15": 1,
  "00:30": 2,    "00:45": 3,
  "01:00": 4,    "01:15": 5,
  "01:30": 6,    "01:45": 7,
  "02:00": 8,    "02:15": 9,
  "02:30": 10,   "02:45": 11,
  "03:00": 12,   "03:15": 13,
  "03:30": 14,   "03:45": 15,
  "04:00": 16,   "04:15": 17,
  "04:30": 18,   "04:45": 19,
  "05:00": 20,   "05:15": 21,
  "05:30": 22,   "05:45": 23,
  "06:00": 24,   "06:15": 25,
  "06:30": 26,   "06:45": 27,
  "07:00": 28,   "07:15": 29,
  "07:30": 30,   "07:45": 31,
  "08:00": 32,   "08:15": 33,
  "08:30": 34,   "08:45": 35,
  "09:00": 36,   "09:15": 37,
  "09:30": 38,   "09:45": 39,
  "10:00": 40,   "10:15": 41,
  "10:30": 42,   "10:45": 43,
  "11:00": 44,   "11:15": 45,
  "11:30": 46,   "11:45": 47,
  "12:00": 48,   "12:15": 49,
  "12:30": 50,   "12:45": 51,
  "13:00": 52,   "13:15": 53,
  "13:30": 54,   "13:45": 55,
  "14:00": 56,   "14:15": 57,
  "14:30": 58,   "14:45": 59,
  "15:00": 60,   "15:15": 61,
  "15:30": 62,   "15:45": 63,
  "16:00": 64,   "16:15": 65,
  "16:30": 66,   "16:45": 67,
  "17:00": 68,   "17:15": 69,
  "17:30": 70,   "17:45": 71,
  "18:00": 72,   "18:15": 73,
  "18:30": 74,   "18:45": 75,
  "19:00": 76,   "19:15": 77,
  "19:30": 78,   "19:45": 79,
  "20:00": 80,   "20:15": 81,
  "20:30": 82,   "20:45": 83,
  "21:00": 84,   "21:15": 85,
  "21:30": 86,   "21:45": 87,
  "22:00": 88,   "22:15": 89,
  "22:30": 90,   "22:45": 91,
  "23:00": 92,   "23:15": 93,
  "23:30": 94,   "23:45": 95,
  "24:00": 96
} as const
export type DisplayTimeTypeAurora = typeof DISPLAY_TIME_AURORA[keyof typeof DISPLAY_TIME_AURORA]
export const DisplayTimeKeyAurora = (value: DisplayTimeTypeAurora | number) => {
// @ts-ignore
  return Object.keys(DISPLAY_TIME_AURORA).find(key => DISPLAY_TIME_AURORA[key] === value)
}


/**
 * 表示時間
 */
export const STATUS = {
  "あああ": 0,
  "予約中": 1,
  "診療開始": 2,
  "医師待機中": 3,
  "診察開始": 4,
  "診察終了": 5,
  "退室済み": 70,
  "キャンセル（利用者）": 80,
  "キャンセル（医師）": 81,
  "キャンセル（システム管理者）": 82,
  "支払い済み": 90,
  "支払い失敗": 91,
} as const
export type StatusType = typeof STATUS[keyof typeof STATUS]
export const StatusKey = (value: StatusType | number) => {
// @ts-ignore
  return Object.keys(STATUS).find(key => STATUS[key] === value)
}