import firebase from "gatsby-plugin-firebase"
import moment from "moment"
import { DisplayTimeKeyAurora, StatusKey } from "./firestore"
import {
  RESERVE,
} from "../../../functions/src/types"
import dot from "dot-object"
import lodash from "lodash"

export interface IFirestoreAuroraDeliveryInport {
  id: string
  result: {
    tracking_number: string
  }
  shipment_date?: any
}


export interface IExportReserve {
  "id"?: string
  "age"?: string
  "created_at"?: string
  "date"?: string
  "delivery_request"?: string
  "doctor_id"?: string
  "doctor_name"?: string
  "first"?: string
  "from"?: string
  "gender"?: string
  "medical_id"?: string
  "medicine_receive_type"?: string
  "patient_id"?: string
  "patient_name"?: string
  "payment_method"?: string
  "period_status"?: string
  "status"?: string
  "to"?: string
  "type"?: string
  "updated_at"?: string
  "shipment_date"?: string
  "parent_reserve"?: string
  "amount"? : string
  "delivery_fee"? : string
  "discount_fee"? : string
  "gmoTransactionId"? : string
  "medical_fee"? : string
  "menu"? : string
  "np_fee"? : string
  "other_fee"? : string
  "set_count"? : string
  "system_fee"? : string
  "tracking_number"? : string
}

export const ReserveExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "予約ID", key: "id", comment: "予約ID" },
  { type: "string", label: "予約日", key: "date", comment: "予約日" },
  { type: "string", label: "開始時刻", key: "from", comment: "開始時刻" },
  { type: "string", label: "終了時刻", key: "to", comment: "終了時刻" },
  { type: "string", label: "氏名", key: "patient_name", comment: "ご利用者様氏名" },
  { type: "string", label: "性別", key: "gender", comment: "性別" },
  { type: "string", label: "年齢", key: "age", comment: "年齢" },
  { type: "string", label: "配達希望", key: "delivery_request", comment: "配達希望" },
  { type: "string", label: "doctor_id", key: "doctor_id", comment: "予約ID" },
  { type: "string", label: "医師名", key: "doctor_name", comment: "医師名" },
  { type: "string", label: "初回診療", key: "first", comment: "初回診療" },
  { type: "string", label: "medical_id", key: "medical_id", comment: "予約ID" },
  // { type: "string", label: "お薬配送方法", key: "medicine_receive_type", comment: "お薬配送方法" },
  { type: "string", label: "patient_id", key: "patient_id", comment: "予約ID" },
  { type: "string", label: "お支払方法", key: "payment_method", comment: "お支払方法" },
  { type: "string", label: "定期配送ステータス", key: "period_status", comment: "定期配送ステータス" },
  { type: "string", label: "親予約ID(定期元)", key: "parent_reserve", comment: "親予約ID(定期元)" },
  { type: "string", label: "出荷日", key: "shipment_date", comment: "出荷日" },
  { type: "string", label: "予約ステータス", key: "status", comment: "予約ステータス" },
  { type: "string", label: "予約タイプ", key: "type", comment: "予約タイプ" },
  { type: "string", label: "お薬メニュー", key: "menu", comment: "menu"},
  { type: "string", label: "お薬セット数", key: "set_count", comment: "set_count"},
  { type: "string", label: "合計金額", key: "amount", comment: "amount"},
  { type: "string", label: "お薬代金", key: "medical_fee", comment: "medical_fee"},
  { type: "string", label: "配送料", key: "delivery_fee", comment: "delivery_fee"},
  // { type: "string", label: "GMO取引ID", key: "gmoTransactionId", comment: "gmoTransactionId"},
  { type: "string", label: "後払い手数料", key: "np_fee", comment: "np_fee"},
  { type: "string", label: "システム手数料", key: "system_fee", comment: "system_fee"},
  { type: "string", label: "その他手数料", key: "other_fee", comment: "other_fee"},
  { type: "string", label: "値引き", key: "discount_fee", comment: "discount_fee"},
  { type: "string", label: "追跡番号", key: "tracking_number", comment: "tracking_number"},
  { type: "string", label: "データ作成日", key: "created_at", comment: "データ作成日" },
  { type: "string", label: "データ更新日", key: "updated_at", comment: "データ更新日" },
]

export interface IExportAuroraDelivery {
  "id"?: string
  "date"?: string
  "from"?: string
  "to"?: string
  "patient_name"?: string
  "gender"?: string
  "age"?: string
  "delivery_request"?: string
  "doctor_id"?: string
  "doctor_name"?: string
  "medical_id"?: string
  "patient_id"?: string
  "payment_method"?: string
  "period_status"?: string
  "parent_reserve"?: string
  "shipment_date"?: string
  "status"?: string
  "type"?: string
  "menu"?: string
  "menu_name"?: string
  "set_count"?: string
  "amount"?: string
  "medical_fee"?: string
  "delivery_fee"?: string
  "np_fee"?: string
  "system_fee"?: string
  "other_fee"?: string
  "discount_fee"?: string
  "reserve_no"?: string
  "memo"?: string
  "tracking_number"?: string
  "created_at"?: string
  "updated_at"?: string
  "post_code"?: string
  "address"?: string
  "tel"?: string
  "mail"?: string
  "next_date"?: string
  "consultation_date"?: string
}

export const AuroraDeliveryExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "予約ID", key: "id", comment: "予約ID" },
  // { type: "string", label: "予約日", key: "date", comment: "予約日" },
  // { type: "string", label: "開始時刻", key: "from", comment: "開始時刻" },
  // { type: "string", label: "終了時刻", key: "to", comment: "終了時刻" },
  { type: "string", label: "氏名", key: "patient_name", comment: "氏名" },
  // { type: "string", label: "性別", key: "gender", comment: "性別" },
  { type: "string", label: "年齢", key: "age", comment: "年齢" },
  // { type: "string", label: "配達希望", key: "delivery_request", comment: "配達希望" },
  // { type: "string", label: "doctor_id", key: "doctor_id", comment: "医師ID" },
  // { type: "string", label: "医師名", key: "doctor_name", comment: "医師名" },
  // { type: "string", label: "medical_id", key: "medical_id", comment: "医療機関ID" },
  { type: "string", label: "patient_id", key: "patient_id", comment: "利用者ID" },
  { type: "string", label: "お支払方法", key: "payment_method", comment: "お支払方法" },
  // { type: "string", label: "定期配送ステータス", key: "period_status", comment: "定期配送ステータス" },
  // { type: "string", label: "親予約ID(定期元)", key: "parent_reserve", comment: "親予約ID(定期元)" },
  // { type: "string", label: "出荷日", key: "shipment_date", comment: "出荷日" },
  // { type: "string", label: "予約ステータス", key: "status", comment: "予約ステータス" },
  { type: "string", label: "お薬メニュー", key: "menu", comment: "menu"},
  { type: "string", label: "お薬メニュー名", key: "menu_name", comment: "menu_name"},
  { type: "string", label: "お薬セット数", key: "set_count", comment: "set_count"},
  { type: "string", label: "合計金額", key: "amount", comment: "amount"},
  { type: "string", label: "お薬代金", key: "medical_fee", comment: "medical_fee"},
  { type: "string", label: "配送料", key: "delivery_fee", comment: "delivery_fee"},
  { type: "string", label: "後払い手数料", key: "np_fee", comment: "np_fee"},
  { type: "string", label: "システム手数料", key: "system_fee", comment: "system_fee"},
  { type: "string", label: "その他手数料", key: "other_fee", comment: "other_fee"},
  { type: "string", label: "値引き", key: "discount_fee", comment: "discount_fee"},
  { type: "string", label: "予約番号", key: "reserve_no", comment: "reserve_no"},
  // { type: "string", label: "メモ", key: "memo", comment: "メモ"},
  // { type: "string", label: "追跡番号", key: "tracking_number", comment: "追跡番号"},
  // { type: "string", label: "データ作成日", key: "created_at", comment: "データ作成日" },
  // { type: "string", label: "データ更新日", key: "updated_at", comment: "データ更新日" },
  { type: "string", label: "郵便番号", key: "post_code", comment: "郵便番号" },
  { type: "string", label: "住所", key: "address", comment: "住所" },
  { type: "string", label: "電話番号", key: "tel", comment: "電話番号" },
  { type: "string", label: "メールアドレス", key: "mail", comment: "メールアドレス" },
  { type: "string", label: "次回お届け日", key: "next_date", comment: "次回お届け日" },
  { type: "string", label: "診察日", key: "consultation_date", comment: "診察日" },
  { type: "string", label: "予約タイプ", key: "type", comment: "予約タイプ" },
]

interface ICSVHeader {
  type: string
  label: string
  key: string
  comment: string
}

export interface IAuroraDeliveryInportCSV {
  id: string
  date: string
  delivery_type: string
  tracking_no: string
}

export const AuroraDeliveryInportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "予約ID", key: "id", comment: "電話番号をハイフン無しで入力" },
  { type: "string", label: "発送日", key: "date", comment: "機種依存なしの文言で医療機関名" },
  { type: "string", label: "配送方法", key: "delivery_type", comment: "FAX番号をハイフン無しで入力" },
  { type: "string", label: "配送番号", key: "tracking_no", comment: "" },
]

interface IUserData {
  id: string
  data: firebase.firestore.DocumentData
}

export interface IRegisterDeliveryInportResponse {
  index: number
  result: string
}

interface IMenuData {
  id: string
  data: firebase.firestore.DocumentData
}

// 曜日の短縮形をマッピング
const weekdaysShort = ['日', '月', '火', '水', '木', '金', '土'];

//ID,患者コード,氏名,読み仮名,性別,メールアドレス,電話番号,郵便番号,住所,生年月日,アレルギー情報,服薬中のお薬・治療中のご病気,特記事項,メールマガジンの受け取り,前回来院,次回来院,流入元,流入元 (自由入力),紹介コード,来院者タグ,作成日時,更新日時
export const getReserveListByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, dateFrom: Date | null, dateTo: Date | null, setProgressNum: any) => {
  let retVal: IExportReserve[] = []
  try {
    if (firestore) {
      setProgressNum(0)
      // const usersSnapShot = await firestore.collection("private").where("type", '==', 7).get()
      const menusSnap = await firestore.collection("aurora_menu").get()
      const reserves = await firestore.collection("aurora_reserve")
        .where("date", '>=', moment(dateFrom).valueOf())
        .where("date", '<=', moment(dateTo).valueOf()).get()
      setProgressNum(5)

      const menus: IMenuData[] = menusSnap.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }))

      // const users: IUserData[] = usersSnapShot.docs.map(doc => ({
      //   id: doc.id,
      //   data: doc.data()
      // }))

      console.log("menus", menus)

      let i = 0
      const reserves_count = reserves.size
      reserves.forEach(reserve => {
        i++
        const reserve_data = reserve.data()

        let period_status = ""
        switch(reserve_data.period_status) {
          case 0:
            period_status = "-"
            break;
          case 1:
            period_status = "配送中"
            break;
          case 2:
            period_status = "配送終了"
            break;
          default:
            period_status = "定期"
            break;
        }

        retVal.push({
          id: reserve.id,
          patient_name: reserve_data.patient_name,
          date: moment(reserve_data.date).format("YYYY/MM/DD"),
          from: DisplayTimeKeyAurora(reserve_data.from),
          to: DisplayTimeKeyAurora(reserve_data.to),
          age: reserve_data.age,
          created_at: moment(reserve_data.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
          delivery_request: reserve_data.delivery_request === 1 ? "当日" : "翌日以降",
          doctor_id: reserve_data.doctor_id,
          doctor_name: reserve_data.doctor_name,
          first: reserve_data.first ? "初診" : "再診",
          gender: reserve_data.gender === 1 ? "男性" : "女性",
          medical_id: reserve_data.medical_id,
          // medicine_receive_type: reserve_data.medicine_receive_type,
          patient_id: reserve_data.patient_id,
          payment_method: reserve_data.payment_method === 0 ? "クレジットカード" : "コンビニ後払い",
          period_status: period_status,
          status: StatusKey(reserve_data.status),
          type: reserve_data.type === 2 ? "通常予約" : "定期配送",
          updated_at: moment(reserve_data.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
          shipment_date: reserve_data.shipment_date ? moment(reserve_data.shipment_date).format("YYYY/MM/DD") : "",
          parent_reserve: reserve_data.parent_reserve,
          amount: reserve_data.result?.amount ?? "" ,
          delivery_fee: reserve_data.result?.delivery_fee ?? "" ,
          discount_fee: reserve_data.result?.discount_fee ?? "" ,
          // gmoTransactionId: reserve_data.result?.gmoTransactionId ?? "" ,
          medical_fee: reserve_data.result?.medical_fee ?? "" ,
          menu: reserve_data.result?.menu && reserve_data.result?.menu.length > 0 ? reserve_data.result?.menu.map((v: any) => menus.find(menu => menu.id === v)?.data.name).join(",") : "",
          // menu: reserve_data.result?.menu ?? "" ,
          np_fee: reserve_data.result?.np_fee ?? "" ,
          other_fee: reserve_data.result?.other_fee ?? "" ,
          set_count: reserve_data.result?.set_count ?? "" ,
          system_fee: reserve_data.result?.system_fee ?? "" ,
          tracking_number: reserve_data.result?.tracking_number ?? "" ,
        })
        setProgressNum(1 + Math.round((i / reserves_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

interface IAuthUser {
  id: string
  email: string
}

//オーロラの配送外注へ送る用CSV
export const getAuroraDeliveryListByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, dateFrom: Date | null, dateTo: Date | null, setProgressNum: any) => {
  let retVal: IExportAuroraDelivery[] = []
  try {
    if (firestore) {
      console.log("getAuroraDeliveryListByCSV")
      setProgressNum(0)
      const menusSnap = await firestore.collection("aurora_menu").get()
      const reserves = await firestore.collection("aurora_reserve")
        .where("payment_date", '>=', dateFrom)
        .where("payment_date", '<=', dateTo).get()
      setProgressNum(5)

      const menus: IMenuData[] = menusSnap.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }))

      //aurora_medicalからaurora_reserve.medical_idで病院情報を取得
      const medicalsSnap = await firestore.collection("aurora_medical").get()
      const medical_data = medicalsSnap.docs[0].data()

      //privateから、IDで医師情報を取得
      const doctorsSnap = await firestore.collection("private").where(firebase.firestore.FieldPath.documentId(), '==', medical_data.pharmacists[0]).get()
      const doctor_data = doctorsSnap.docs[0].data()

      // patient_id のリストを取得
      const patientIds = reserves.docs.map(doc => doc.data().patient_id)
      // 10 件ずつのチャンクに分割
      const chunks = chunkArray(patientIds, 10)
      const privateDataList : any[] = []

      for (const chunk of chunks) {
        const privateSnap = await firestore.collection("private").where(firebase.firestore.FieldPath.documentId(), 'in', chunk).get()
        privateSnap.forEach(doc => {
          privateDataList.push({
            id: doc.id,
            ...doc.data()
          })
        })
      }

      //メール取得
      //メールアドレス
      let mailList : IAuthUser[] = [{id: "", email:""}]
      if(functions){
        console.log("mail start")
        const getUsersEmailsFunction = functions.httpsCallable("getUsersEmails",{timeout : 550 * 1000})
        const result = await getUsersEmailsFunction({ patient_ids: patientIds });
        mailList = result.data.userInfos
      }
      console.log("getAuroraDeliveryListByCSV1", mailList)

      let i = 0
      const reserves_count = reserves.size
      console.log("getAuroraDeliveryListByCSV2", reserves_count)
      reserves.forEach(reserve => {
        i++
        const reserve_data = reserve.data()
        const privateData = privateDataList.find(p => p.id === reserve_data.patient_id)
        if(reserve_data.result?.menu && reserve_data.result?.menu.length > 0) {
          retVal.push({
            id: reserve.id,
            date: reserve_data.date ?? "" ,
            from: reserve_data.from ?? "" ,
            to: reserve_data.to ?? "" ,
            patient_name: reserve_data.patient_name ?? "" ,
            gender: reserve_data.gender ?? "" ,
            age: reserve_data.age ?? "" ,
            delivery_request: reserve_data.delivery_request ?? "" ,
            doctor_id: reserve_data.doctor_id ?? "" ,
            doctor_name: doctor_data.name ?? "" ,
            medical_id: reserve_data.medical_id ?? "" ,
            patient_id: reserve_data.patient_id ?? "" ,
            payment_method: reserve_data.payment_method === 0 ? "クレジットカード" : "コンビニ後払い",
            period_status: reserve_data.period_status ?? "" ,
            parent_reserve: reserve_data.parent_reserve ?? "" ,
            shipment_date: reserve_data.shipment_date ?? "" ,
            status: reserve_data.status ?? "" ,
            type: reserve_data.type == RESERVE.PHARMACY_RESERVE ? "新規" : "定期" ,
            menu: reserve_data.result?.menu && reserve_data.result?.menu.length > 0 ? `[${reserve_data.result?.menu.map((v: any) => menus.find(menu => menu.id === v)?.id).join(",")}]` : "[]",
            menu_name: reserve_data.result?.menu && reserve_data.result?.menu.length > 0 ? `[${reserve_data.result?.menu.map((v: any) => menus.find(menu => menu.id === v)?.data.name).join(",")}]` : "[]",
            set_count: reserve_data.result?.set_count ?? "0" ,
            amount: reserve_data.result?.amount ?? "0" ,
            medical_fee: reserve_data.result?.medical_fee ?? "0" ,
            delivery_fee: reserve_data.result?.delivery_fee ?? "0" ,
            np_fee: reserve_data.result?.np_fee ?? "0" ,
            system_fee: reserve_data.result?.system_fee ?? "0" ,
            other_fee: reserve_data.result?.other_fee ?? "0" ,
            discount_fee: reserve_data.result?.discount_fee ?? "0" ,
            reserve_no: reserve_data.reserve_no ?? "" ,
            memo: reserve_data.memo ?? "" ,
            tracking_number: reserve_data.result?.tracking_number ?? "" ,
            created_at: reserve_data.created_at ? moment(Number(reserve_data.created_at.seconds.toString() + "000")).format("YYYY/MM/DD HH:mm:ss") : "",
            updated_at: reserve_data.updated_at ? moment(Number(reserve_data.updated_at.seconds.toString() + "000")).format("YYYY/MM/DD HH:mm:ss") : "",
            post_code: privateData?.post_code ?? "",
            address: `${privateData?.prefecture}${privateData?.address1}${privateData?.address2}`,
            tel: privateData?.tel ?? "",
            mail: mailList.find((user: any) => user.id === reserve_data.patient_id)?.email ?? "",
            next_date: getNextDate(reserve_data, menus),
            consultation_date: moment(reserve_data.date).format("YYYY/MM/DD"),
          })
        }
        setProgressNum(1 + Math.round((i / reserves_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}
// 配列をチャンクに分割する関数
function chunkArray(array : any[], size : number) {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    chunked_arr.push(chunk);
  }
  return chunked_arr;
}

//next_dateを計算して返す関数
export const getNextDate = (reserve: any, menuList: any[]) => {
  let date = undefined
  const shipment_date = moment(reserve.shipment_date)
  if (reserve.type === 2) {

    //prescription_daysの入っているメニューがある場合、定期2回目のみprescription_daysをお薬処方日数とする
    //デフォルトの日数は26日
    let first_prescription_days = 26
    reserve?.result?.menu.forEach((v: any) => {
      const result = menuList.find(menu => menu.id === v)
      if (result && result.prescription_days && result.prescription_days < first_prescription_days) {
        first_prescription_days = result.prescription_days
      }
    })

    first_prescription_days = first_prescription_days + (28 * (reserve?.result?.set_count - 1))

    // 定期購入2回目：前回発送日 + ピル：18日　トライアル：10日　その他：26日 + 28日 * カウント数　
    date = shipment_date.add(first_prescription_days, "d").valueOf()
  }else{
    // 定期購入3回目以降：前回発送日 + お薬処方日数(28日) * カウント数に作成される
    const after_prescription_days = 28 * reserve?.result?.set_count
    date = shipment_date.add(after_prescription_days, "d").valueOf()
  }

  //配送準備日が、現在時刻+8日よりも前の日付の場合、
  const days_after = moment().startOf("day").add(8,"d").valueOf()
  if(date < days_after.valueOf()){
    date = days_after
  }

  const tmp = moment(date)

  return `${tmp.format('MM月DD日')}(${weekdaysShort[tmp.day()]})`
}

export const convertAuroraDeliveryInportCSVToJSON = (data: string[]) => {
  let retVal = {}
  data.forEach((value, index) => {
    const key = AuroraDeliveryInportCSVHeader[index].key
    let tempVal = undefined
    if (AuroraDeliveryInportCSVHeader[index].type === "boolean") {
      // 強制的にBoolean変換
      // @ts-ignore
      tempVal = !!value
    } else {
      // @ts-ignore
      tempVal = value
    }

    const obj = dot.object({ [key]: tempVal })
    lodash.merge(retVal, obj)
  })

  console.log("retVal",retVal)


  return retVal
}


export const convertAuroraDeliveryInportJsonToFB = async (data: IAuroraDeliveryInportCSV): Promise<IFirestoreAuroraDeliveryInport | null> => {
  let retVal = null

  const temp: IFirestoreAuroraDeliveryInport = {
    id: data.id,
    result: {
      tracking_number: data.tracking_no
    },
    shipment_date: moment(data.date).startOf("day").valueOf()
  }

  retVal = temp

  return retVal
}

export const registerAuroraDeliveryInport = (firestore: firebase.firestore.Firestore | undefined, data: Array<IFirestoreAuroraDeliveryInport | null>) => {
  // undefinedのやつは登録から弾く
  if (firestore) {
    const promiseArray = data.map((value, index) => {
      return async (): Promise<IRegisterDeliveryInportResponse> => {
        let result = ""
        if (value) {
          try {
            const reserve_id = value.id
            const reserve = await firestore.collection("aurora_reserve").doc(reserve_id).get()
            if (!reserve) {
              result = `予約IDが存在しないため、スキップしました。 id:${reserve_id}`
            } else {
              const reserve_data = reserve.data()
              if(reserve_data && reserve_data.result){
                await firestore.collection("aurora_reserve").doc(reserve_id).update({
                  result: {
                    ...reserve_data.result,
                    tracking_number: value.result?.tracking_number
                  },
                  shipment_date: value.shipment_date
                })
                result = "登録が完了しました"
              } else {
                result = `予約データが存在しないため、スキップしました。 id:${reserve_id}`
              }
            }
          } catch (e) {
            console.log(e)
            result = "不明なエラーが発生しました。"
          }
        } else {
          result = "スキップされました"
        }

        return ({
          index: index + 2,
          result
        })
      }
    })

    const arrayResult: IRegisterDeliveryInportResponse[] = []
    return promiseArray.reduce((prev, current) => {
      return prev.then(value => {
        arrayResult.push(value)
        return current()
      })
    }, Promise.resolve({
      index: 1,
      result: "先頭行はスキップされます"
    })).then((result) => {
      arrayResult.push(result)
      return arrayResult
    })
  } else {
    const temp: IRegisterDeliveryInportResponse = {
      index: 0,
      result: "接続が中断されました。時間をおいてお試しください"
    }
    return Promise.resolve([temp])
  }
}
