import React, { FunctionComponent } from "react"
import { AuroraDeliveryUpload } from "./AuroraDelivery/auroraDeliveryUpload"
import { Typography } from "@material-ui/core"

export const AuroraDeliveryPageInput: FunctionComponent = () => {
  return (
    <>
      <AuroraDeliveryUpload />
    </>
  )
}
