import firebase from "gatsby-plugin-firebase"
import * as nGram from "n-gram"
import * as geohash from "ngeohash"
import dot from "dot-object"
import lodash from "lodash"
import { INSTITUTION, RESERVE } from "../../../functions/src/types"
import moment from "moment"

export interface IFirestoreMedical {
  type: number
  name: string
  tel: string
  fax: string
  url?: string
  images: []
  tags?: string[]
  post_code: string
  prefecture: string
  address1: string
  address2: string
  description: string
  warning?: string
  start: number
  end: number
  day_of_week: {
    mon: boolean
    tue: boolean
    wed: boolean
    thu: boolean
    fri: boolean
    sat: boolean
    sun: boolean
  }
  search: any
  coordinates: firebase.firestore.GeoPoint
  g: {
    geohash: string,
    geopoint: firebase.firestore.GeoPoint
  }
  first_visit?: boolean
  re_visit?: boolean
  rate?: number
  created_at: firebase.firestore.FieldValue
  updated_at: firebase.firestore.FieldValue
  reception_times?: {
    mon?: IReception
    tue?: IReception
    wed?: IReception
    thu?: IReception
    fri?: IReception
    sat?: IReception
    sun?: IReception
  }
  bank_name?: string
  bank_branch_name?: string
  bank_account_type?:　number
  bank_branch_code?: string
  bank_account_number?: string
  bank_account_holder?: string
  delivery_company?: string
  pickup_time?: string
}

export const RESERVE_TIME = [
  "00:00", "00:30",
  "01:00", "01:30",
  "02:00", "02:30",
  "03:00", "03:30",
  "04:00", "04:30",
  "05:00", "05:30",
  "06:00", "06:30",
  "07:00", "07:30",
  "08:00", "08:30",
  "09:00", "09:30",
  "10:00", "10:30",
  "11:00", "11:30",
  "12:00", "12:30",
  "13:00", "13:30",
  "14:00", "14:30",
  "15:00", "15:30",
  "16:00", "16:30",
  "17:00", "17:30",
  "18:00", "18:30",
  "19:00", "19:30",
  "20:00", "20:30",
  "21:00", "21:30",
  "22:00", "22:30",
  "23:00", "23:30",
  "24:00" ]

export interface ICSVHeader {
  type: string
  label: string
  key: string
  comment: string
}

export interface IMedicalDepartment {
  type: string
  name: string
  tel: string
  fax: string
  address: string
  department: string
  is_first: string
  is_re: string
  in_hospital_prescription: string
  day_of_week_mon: string
  day_of_week_tue: string
  day_of_week_wed: string
  day_of_week_thu: string
  day_of_week_fri: string
  day_of_week_sat: string
  day_of_week_sun: string
  bank_name: string
  bank_branch_name: string
  bank_account_type: string
  bank_branch_code: string
  bank_account_number: string
  bank_account_holder: string
  created_at: string
  delivery_company: string
  pickup_time: string
  today_reservable_count: string
}

export const MedicalDepartmentCSVHeader: ICSVHeader[] = [
  {
    type: "string",
    label: "タイプ",
    key: "type",
    comment: "「クリニック」または「薬局」が出力されます"
  },
  {
    type: "string",
    label: "医療機関名",
    key: "name",
    comment: ""
  },
  {
    type: "string",
    label: "TEL",
    key: "tel",
    comment: ""
  },
  {
    type: "string",
    label: "FAX",
    key: "fax",
    comment: ""
  },
  {
    type: "string",
    label: "住所",
    key: "address",
    comment: "郵便番号 + 都道府県 + 住所1 + 住所2"
  },
  {
    type: "string",
    label: "診療科",
    key: "department",
    comment: "診療科の名称"
  },
  {
    type: "string",
    label: "初診可",
    key: "is_first",
    comment: "初診可能が設定されている場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "再診可",
    key: "is_re",
    comment: "再診可能が設定されている場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "院内診療",
    key: "in_hospital_prescription",
    comment: "院内診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "月曜",
    key: "day_of_week_mon",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "火曜",
    key: "day_of_week_tue",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "水曜",
    key: "day_of_week_wed",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "木曜",
    key: "day_of_week_thu",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "金曜",
    key: "day_of_week_fri",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "土曜",
    key: "day_of_week_sat",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "日曜",
    key: "day_of_week_sun",
    comment: "診療可能の場合「○」、それ以外の場合は「×」となります"
  },
  {
    type: "string",
    label: "銀行名",
    key: "bank_name",
    comment: ""
  },
  {
    type: "string",
    label: "支店名",
    key: "bank_branch_name",
    comment: ""
  },
  {
    type: "string",
    label: "口座種別",
    key: "bank_account_type",
    comment: "普通/当座"
  },
  {
    type: "string",
    label: "支店番号",
    key: "bank_branch_code",
    comment: ""
  },
  {
    type: "string",
    label: "口座番号",
    key: "bank_account_number",
    comment: ""
  },
  {
    type: "string",
    label: "名義(ｶﾅ)",
    key: "bank_account_holder",
    comment: ""
  },
  {
    type: "string",
    label: "登録年月日",
    key: "created_at",
    comment: "YYYY/MM/DD HH-MM-SS"
  },
  {
    type: "string",
    label: "配送業者",
    key: "delivery_company",
    comment: ""
  },
  {
    type: "string",
    label: "集荷時間",
    key: "pickup_time",
    comment: ""
  },
  {
    type: "string",
    label: "本日の空き予約枠",
    key: "today_reservable_count",
    comment: ""
  }
]

export const MedicalCSVHeader: ICSVHeader[] = [
  { type: "boolean", label: "取り込み対象", key: "enable", comment: "「true」：SOKUYAKUに新規登録されます、空：行をスキップしてSOKUYAKUへ登録されません" },
  { type: "number", label: "タイプ", key: "type", comment: "「0」を指定するとクリニック、「1」を指定すると薬局" },
  { type: "string", label: "医療機関名", key: "name", comment: "機種依存なしの文言で医療機関名" },
  { type: "string", label: "電話番号", key: "tel", comment: "電話番号をハイフン無しで入力" },
  { type: "string", label: "FAX", key: "fax", comment: "FAX番号をハイフン無しで入力" },
  { type: "string", label: "URL", key: "url", comment: "" },
  { type: "string", label: "タグ", key: "tags", comment: "タグをカンマ「,」区切りで入力" },
  { type: "string", label: "郵便番号", key: "post_code", comment: "郵便番号をハイフン区切りで入力" },
  { type: "string", label: "都道府県", key: "prefecture", comment: "都道府県を入力" },
  { type: "string", label: "住所1", key: "address1", comment: "市区町村を入力" },
  { type: "string", label: "住所2", key: "address2", comment: "番地以下を入力" },
  { type: "string", label: "概要", key: "description", comment: "" },
  { type: "string", label: "注意事項", key: "warning", comment: "" },
  { type: "number", label: "営業開始時刻", key: "start", comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)" },
  { type: "number", label: "営業終了時刻", key: "end", comment: "0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)" },
  { type: "boolean", label: "月曜日の営業", key: "mon", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "火曜日の営業", key: "tue", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "水曜日の営業", key: "wed", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "木曜日の営業", key: "thu", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "金曜日の営業", key: "fri", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "土曜日の営業", key: "sat", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "日曜日の営業", key: "sun", comment: "「true」：営業、空：休日" },
  { type: "boolean", label: "初回診療可", key: "first_visit", comment: "「true」：初診可(薬局の場合、trueを設定しても無視されます)、空：不可" },
  { type: "boolean", label: "再診診療可", key: "first_visit", comment: "「true」：再診可(薬局の場合、trueを設定しても無視されます)、空：不可" },
  { type: "number", label: "月曜日の来店受付開始時刻", key: "reception_times.mon.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "月曜日の来店受付終了時刻", key: "reception_times.mon.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "火曜日の来店受付開始時刻", key: "reception_times.tue.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "火曜日の来店受付終了時刻", key: "reception_times.tue.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "水曜日の来店受付開始時刻", key: "reception_times.wed.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "水曜日の来店受付終了時刻", key: "reception_times.wed.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "木曜日の来店受付開始時刻", key: "reception_times.thu.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "木曜日の来店受付終了時刻", key: "reception_times.thu.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "金曜日の来店受付開始時刻", key: "reception_times.fri.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "金曜日の来店受付終了時刻", key: "reception_times.fri.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "土曜日の来店受付開始時刻", key: "reception_times.sat.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "土曜日の来店受付終了時刻", key: "reception_times.sat.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "日曜日の来店受付開始時刻", key: "reception_times.sun.from", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "number", label: "日曜日の来店受付終了時刻", key: "reception_times.sun.to", comment: "※タイプが「1」(薬局)の場合必須。 0～47のいずれかの数字を入力。(00:00～23:30まで30分区切りで対応)、空：休日　※開始、終了のどちらかが空の場合、休日とする" },
  { type: "string", label: "銀行名", key: "bank_name", comment: "" },
  { type: "string", label: "支店名", key: "bank_branch_name", comment: "" },
  { type: "number", label: "口座種別", key: "bank_account_type", comment: "「1」を指定すると普通、「2」を指定すると当座" },
  { type: "string", label: "支店番号", key: "bank_branch_code", comment: "" },
  { type: "string", label: "口座番号", key: "bank_account_number", comment: "" },
  { type: "string", label: "名義(ｶﾅ)", key: "bank_account_holder", comment: "" },
  { type: "string", label: "配送業者", key: "delivery_company", comment: "" },
  { type: "string", label: "集荷時間", key: "pickup_time", comment: "" },
]

interface IReception {
  from?: number,
  to?: number
}

interface IReceptionTimes {
  mon?: IReception
  tue?: IReception
  wed?: IReception
  thu?: IReception
  fri?: IReception
  sat?: IReception
  sun?: IReception
}

export interface IMedicalCSV {
  enable: boolean
  type: number
  name: string
  tel: string
  fax: string
  url: string
  tags: string
  post_code: string
  prefecture: string
  address1: string
  address2: string
  description: string
  warning: string
  start: number
  end: number
  mon: boolean
  tue: boolean
  wed: boolean
  thu: boolean
  fri: boolean
  sat: boolean
  sun: boolean
  first_visit?: boolean
  re_visit?: boolean
  reception_times?: IReceptionTimes
  bank_name?: string
  bank_branch_name?: string
  bank_account_type?: number
  bank_branch_code?: string
  bank_account_number?: string
  bank_account_holder?: string
  delivery_company?: string
  pickup_time?: string
}

export const convertMedicalCSVToJSON = (data: string[]) => {
  let retVal = {}
  data.forEach((value, index) => {
    const key = MedicalCSVHeader[index].key
    let tempVal = undefined
    if (MedicalCSVHeader[index].type === "boolean") {
      // 強制的にBoolean変換
      // @ts-ignore
      tempVal = !!value
    } else {
      // @ts-ignore
      tempVal = value
    }

    const obj = dot.object({ [key]: tempVal })
    lodash.merge(retVal, obj)
  })

  //from,toのどちらか一か所空白ならもう片方も空白に変換
  const week = ["mon","tue","wed","thu","fri","sat","sun"]
  week.forEach(value => {
    // @ts-ignore
    const tmp = retVal["reception_times"][value]
    if(tmp.from.length == 0 || tmp.to.length == 0 ){
      tmp.from = null
      tmp.to = null
    }
  })

  // @ts-ignore
  if (retVal["bank_account_type"].length == 0) {
    // @ts-ignore
    retVal["bank_account_type"] = null
  }

  console.log("retVal",retVal)


  return retVal
}

const getGeocode = async (address: string) => {
  const GeocodingURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAkTUIMKP8kcE5ZXotptaIccgZqDdcHuIA`
  const result = await fetch(GeocodingURL)
  return await result.json()
}

export const convertMedicalJsonToFB = async (data: IMedicalCSV): Promise<IFirestoreMedical | null> => {
  let retVal = null
  if (data.enable) {
    // firstVisitを設定
    let tempFirstVisit = undefined
    let tempReVisit = undefined
    let tempRate = undefined
    if (data.type === 0) {
      // clinic
      tempFirstVisit = data.first_visit ? data.first_visit : false
      tempReVisit = data.re_visit ? data.re_visit : false
      tempRate = 3
    }

    let reception_times : IReceptionTimes | undefined = undefined
    if (data.type === 1) {
      console.log("data",data)
      // pharmacy
      const rec_mon : IReception | undefined = data.reception_times?.mon?.from && data.reception_times?.mon?.to ? {
        from: data.reception_times.mon.from,
        to: data.reception_times.mon.to,
      } : undefined
      const rec_tue : IReception | undefined = data.reception_times?.tue?.from && data.reception_times?.tue?.to ? {
        from: data.reception_times.tue.from,
        to: data.reception_times.tue.to,
      } : undefined
      const rec_wed : IReception | undefined = data.reception_times?.wed?.from && data.reception_times?.wed?.to ? {
        from: data.reception_times.wed.from,
        to: data.reception_times.wed.to,
      } : undefined
      const rec_thu : IReception | undefined = data.reception_times?.thu?.from && data.reception_times?.thu?.to ? {
        from: data.reception_times.thu.from,
        to: data.reception_times.thu.to,
      } : undefined
      const rec_fri : IReception | undefined = data.reception_times?.fri?.from && data.reception_times?.fri?.to ? {
        from: data.reception_times.fri.from,
        to: data.reception_times.fri.to,
      } : undefined
      const rec_sat : IReception | undefined = data.reception_times?.sat?.from && data.reception_times?.sat?.to ? {
        from: data.reception_times.sat.from,
        to: data.reception_times.sat.to,
      } : undefined
      const rec_sun : IReception | undefined = data.reception_times?.sun?.from && data.reception_times?.sun?.to ? {
        from: data.reception_times.sun.from,
        to: data.reception_times.sun.to,
      } : undefined


      reception_times = {
        mon : rec_mon,
        tue : rec_tue,
        wed : rec_wed,
        thu : rec_thu,
        fri : rec_fri,
        sat : rec_sat,
        sun : rec_sun
      }
    }

    console.log("reception_times",reception_times)

    // 位置情報を取得
    const address = data.prefecture + data.address1 + data.address2
    const geo = await getGeocode(address)
    const latitude = geo.results[0].geometry.location.lat
    const longitude = geo.results[0].geometry.location.lng
    const hash = geohash.encode(latitude, longitude)
    const tempTag = data.tags ? data.tags.split(",") : undefined

    // 検索用に検索キーワードを登録
    let search: any[] = []
    search = search.concat(nGram.bigram(data.prefecture))
    search = search.concat(nGram.bigram(data.address1))
    search = search.concat(nGram.bigram(data.name))
    if (tempTag) {
      search = search.concat(nGram.bigram(tempTag.join("")))
    }
    const searchMap: any = {}
    search.forEach(word => searchMap[word] = true)

    const temp: IFirestoreMedical = {
      type: data.type,
      name: data.name,
      tel: data.tel,
      fax: data.fax,
      url: data.url ? data.url : undefined,
      tags: data.tags ? data.tags.split(",") : undefined,
      post_code: data.post_code,
      prefecture: data.prefecture,
      address1: data.address1,
      address2: data.address2,
      description: data.description,
      warning: data.warning ? data.warning : undefined,
      start: data.start,
      end: data.end,
      day_of_week: {
        mon: data.mon,
        tue: data.tue,
        wed: data.wed,
        thu: data.thu,
        fri: data.fri,
        sat: data.sat,
        sun: data.sun
      },
      search: searchMap,
      coordinates: new firebase.firestore.GeoPoint(latitude, longitude),
      g: {
        geohash: hash,
        geopoint: new firebase.firestore.GeoPoint(latitude, longitude)
      },
      first_visit: tempFirstVisit,
      re_visit: tempReVisit,
      rate: tempRate,
      images: [],
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      reception_times : data.type === INSTITUTION.PHARMACY ? reception_times : undefined,
      bank_name: data.bank_name ? data.bank_name : undefined,
      bank_branch_name: data.bank_branch_name ? data.bank_branch_name : undefined,
      bank_account_type: data.bank_account_type ? data.bank_account_type : undefined,
      bank_branch_code: data.bank_branch_name ? data.bank_branch_name : undefined,
      bank_account_number: data.bank_account_number ? data.bank_account_number : undefined,
      bank_account_holder: data.bank_account_holder ? data.bank_account_holder : undefined,
      delivery_company: data.delivery_company ? data.delivery_company : undefined,
      pickup_time: data.pickup_time ? data.pickup_time : undefined
    }

    retVal = temp
  }

  return retVal
}

export interface IRegisterResponse {
  index: number
  result: string
}

export const registerMedical = (firestore: firebase.firestore.Firestore | undefined, data: Array<IFirestoreMedical | null>) => {
  // undefinedのやつは登録から弾く
  if (firestore) {
    const promiseArray = data.map((value, index) => {
      return async (): Promise<IRegisterResponse> => {
        let result = ""
        if (value) {
          try {
            const name = value.name
            const find = await firestore.collection("medical").where("name", "==", name).get()
            if (find.size) {
              result = "同一の医療機関名が存在するためスキップされました"
            } else {
              await firestore.collection("medical").add(value)
              result = "登録が完了しました"
            }
          } catch (e) {
            console.log(e)
            result = "不明なエラーが発生しました。"
          }
        } else {
          result = "スキップされました"
        }

        return ({
          index: index + 2,
          result
        })
      }
    })

    const arrayResult: IRegisterResponse[] = []
    return promiseArray.reduce((prev, current) => {
      return prev.then(value => {
        arrayResult.push(value)
        return current()
      })
    }, Promise.resolve({
      index: 1,
      result: "先頭行はスキップされます"
    })).then((result) => {
      arrayResult.push(result)
      return arrayResult
    })
  } else {
    const temp: IRegisterResponse = {
      index: 0,
      result: "接続が中断されました。時間をおいてお試しください"
    }
    return Promise.resolve([temp])
  }
}

export const getDepartmentInformationByCSV = async (firestore: firebase.firestore.Firestore) => {
  let retVal: IMedicalDepartment[] = []
  try {
    if (firestore) {
      const departmentsMaster: any = {}
      await firestore.collection("department").get().then(value => value.forEach(doc => {
        departmentsMaster[doc.id] = {
          name: doc.data().name
        }
      }))
      const medicals = await firestore.collection("medical").get()
      medicals.forEach(result => {
        const data = result.data()
        const medicalName = data.name
        const created_at = data.created_at ? moment(data.created_at.toDate()).format("YYYY/MM/DD HH:mm:ss") :  data.updated_at ? moment(data.updated_at.toDate()).format("YYYY/MM/DD HH:mm:ss") : ""
        if (data.departments) {
          data.departments.forEach((department: { id: string | number; in_hospital_prescription?: boolean }) => {
            const name = departmentsMaster[department.id].name
            retVal.push({
              type: data.type === 0 ? "クリニック" : "薬局",
              name: medicalName,
              tel: "t" + data.tel,
              fax: "t" + data.fax,
              address: data.post_code + " " + data.prefecture + data.address1 + data.address2,
              department: name,
              is_first: data.first_visit? "○" : "×",
              is_re: data.re_visit? "○" : "×",
              in_hospital_prescription: department.in_hospital_prescription ? "○" : "×",
              day_of_week_mon: data.day_of_week.mon ? "○" : "×",
              day_of_week_tue: data.day_of_week.tue ? "○" : "×",
              day_of_week_wed: data.day_of_week.wed ? "○" : "×",
              day_of_week_thu: data.day_of_week.thu ? "○" : "×",
              day_of_week_fri: data.day_of_week.fri ? "○" : "×",
              day_of_week_sat: data.day_of_week.sat ? "○" : "×",
              day_of_week_sun: data.day_of_week.sun ? "○" : "×",
              bank_name: data.bank_name,
              bank_branch_name: data.bank_branch_name,
              bank_account_type: data.bank_account_type? (data.bank_account_type == 1 ? "普通" : "当座") : "",
              bank_branch_code: data.bank_branch_code,
              bank_account_number: data.bank_account_number,
              bank_account_holder: data.bank_account_holder,
              created_at: created_at,
              delivery_company:  data.delivery_company,
              pickup_time:  data.pickup_time,
              today_reservable_count:  data.today_reservable_count,
            })
          })
        } else {
          retVal.push({
            type: data.type === 0 ? "クリニック" : "薬局",
            name: medicalName,
            tel: "t" + data.tel,
            fax: "t" + data.fax,
            address: data.post_code + " " + data.prefecture + data.address1 + data.address2,
            department: "",
            is_first: "-",
            is_re: "-",
            in_hospital_prescription: "-",
            day_of_week_mon: data.day_of_week.mon ? "○" : "×",
            day_of_week_tue: data.day_of_week.tue ? "○" : "×",
            day_of_week_wed: data.day_of_week.wed ? "○" : "×",
            day_of_week_thu: data.day_of_week.thu ? "○" : "×",
            day_of_week_fri: data.day_of_week.fri ? "○" : "×",
            day_of_week_sat: data.day_of_week.sat ? "○" : "×",
            day_of_week_sun: data.day_of_week.sun ? "○" : "×",
            bank_name: data.bank_name,
            bank_branch_name: data.bank_branch_name,
            bank_account_type: data.bank_account_type? (data.bank_account_type == 1 ? "普通" : "当座") : "",
            bank_branch_code: data.bank_branch_code,
            bank_account_number: data.bank_account_number,
            bank_account_holder: data.bank_account_holder,
            created_at: created_at,
            delivery_company:  data.delivery_company,
            pickup_time:  data.pickup_time,
            today_reservable_count:  data.today_reservable_count,
          })
        }
      })
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}
