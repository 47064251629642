import React from "react"
import {Router} from "@reach/router"
import {Layout} from "../components/Layout";
import {PrivateRoute} from "../components/Common/privateRoute";
import {Login} from "../components/login";
import {ReservePage} from "../components/reserve";
import {AuroraDeliveryPage} from "../components/auroraDelivery";
import {AuroraDeliveryPageInput} from "../components/auroraDeliveryInput";

const App = () => (
    <Layout>
        <Router basepath={"/app"}>
            <Login path={"/login"}/>
            <PrivateRoute Component={ReservePage} path={"/reserve"}/>
            <PrivateRoute Component={AuroraDeliveryPage} path={"/aurora_delivery"}/>
            <PrivateRoute Component={AuroraDeliveryPageInput} path={"/aurora_delivery_in"}/>
        </Router>
    </Layout>
)

export default App
