import * as y from "yup"
import { localeJP } from "./yup-locale-ja"

y.setLocale(localeJP)

const isValidDate = (dateString) => {
  const regEx = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
  if (!dateString.match(regEx)) return false;  // Invalid format
  const [year, month, day] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  const isValid = date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  return isValid;
};

export const medicalSchema = y.object().shape({
  enable: y.boolean().label("取り込み対象"),
  type: y.number().label("タイプ").integer().required().lessThan(2, "タイプは0または1を入力して下さい"),
  name: y.string().label("医療機関名").required().max(100),
  tel: y.string().label("電話番号").required().min(10).max(20).matches(/^\d{10,20}$/, "電話番号が不正です"),
  fax: y.string().label("FAX").required().min(10).max(20).matches(/^\d{10,20}$/, "FAX番号が不正です"),
  url: y.string().label("URL").url(),
  tags: y.string().label("タグ").trim().max(100),
  post_code: y.string().label("郵便番号").required().matches(/^[0-9]{3}-[0-9]{4}$/, "郵便番号が不正です"),
  prefecture: y.string().label("都道府県").required().max(10),
  address1: y.string().label("住所1").required().max(30),
  address2: y.string().label("住所2").required().max(50),
  description: y.string().label("概要").required().max(1000),
  warning: y.string().label("注意事項").max(300),
  start: y.number().integer().label("営業開始時間").max(47),
  end: y.number().integer().label("営業終了時間").max(47).moreThan(y.ref("start")),
  mon: y.boolean().label("月曜日の営業"),
  tue: y.boolean().label("火曜日の営業"),
  wed: y.boolean().label("水曜日の営業"),
  thu: y.boolean().label("木曜日の営業"),
  fri: y.boolean().label("金曜日の営業"),
  sat: y.boolean().label("土曜日の営業"),
  sun: y.boolean().label("日曜日の営業"),
  first_visit: y.boolean().label("初回診療可"),
  re_visit: y.boolean().label("再診診療可"),
  reception_times: y.object().when("type", {
    is: 1,
    then: y.object().shape({
      mon: y.object().shape({
        from: y.number().integer().nullable().label("月曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("月曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      tue: y.object().shape({
        from: y.number().integer().nullable().label("火曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("火曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      wed: y.object().shape({
        from: y.number().integer().nullable().label("水曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("水曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      thu: y.object().shape({
        from: y.number().integer().nullable().label("木曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("木曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      fri: y.object().shape({
        from: y.number().integer().nullable().label("金曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("金曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      sat: y.object().shape({
        from: y.number().integer().nullable().label("土曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("土曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      }),
      sun: y.object().shape({
        from: y.number().integer().nullable().label("日曜日の来店受付開始時間").max(47),
        to: y.number().integer().nullable().label("日曜日の来店受付営業終了時間").max(47).moreThan(y.ref("from"))
      })
    }),
  }),
  bank_name: y.string().label("銀行名").max(300),
  bank_branch_name: y.string().label("支店名").max(300),
  bank_account_type: y.number().label("タイプ").integer().nullable().min(1,"口座種別は0または1を入力して下さい").max(2,"口座種別は0または1を入力して下さい"),
  bank_branch_code: y.string().label("支店番号").max(300),
  bank_account_number: y.string().label("口座番号").max(300),
  bank_account_holder: y.string().label("名義(ｶﾅ)").max(300),
  delivery_company: y.string().label("配送業者").max(300),
  pickup_time: y.string().label("集荷時間").max(300),
})

export const userSchema = y.object().shape({
  enable: y.boolean().label("取り込み対象"),
  type: y.number().label("タイプ").integer().required().min(1).max(6),
  email: y.string().label("メール").required().email(),
  password: y.string().label("パスワード").required().min(8).matches(/^[a-zA-Z0-9]+$/, "半角英数字で入力してください"),
  name: y.string().label("氏名").required().max(20),
  name_kana: y.string().label("氏名カナ").required().max(40),
  gender: y.number().label("性別").integer().required().min(1).max(2),
  tel: y.string().label("TEL").required().min(10).max(20).matches(/^\d{10,20}$/, "TELが不正です"),
  medical_name: y.string().label("医療機関名").required().max(20),
  department: y.object().when("type", {
    is: 2,
    then: y.object().shape({
      name: y.string().label("診療科名").required(),
      description: y.string().label("診療科の説明").required().max(500),
      in_hospital_prescription: y.boolean().label("院内処方")
    })
  }),
  description: y.string().when("type", {
    is: [ 2, 5 ],
    then: y.string().label("概要").required().max(500)
  }),
  mon: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("月曜勤務").required(),
      start: y.number().label("勤務開始時間（月）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（月）").required().min(0).max(47).moreThan(y.ref("mon.start"))
    })
  }),
  tue: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("火曜勤務").required(),
      start: y.number().label("勤務開始時間（火）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（火）").required().min(0).max(47).moreThan(y.ref("tue.start"))
    })
  }),
  wed: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("水曜勤務").required(),
      start: y.number().label("勤務開始時間（水）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（水）").required().min(0).max(47).moreThan(y.ref("wed.start"))
    })
  }),
  thu: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("木曜勤務").required(),
      start: y.number().label("勤務開始時間（木）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（木）").required().min(0).max(47).moreThan(y.ref("thu.start"))
    })
  }),
  fri: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("金曜勤務").required(),
      start: y.number().label("勤務開始時間（金）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（金）").required().min(0).max(47).moreThan(y.ref("fri.start"))
    })
  }),
  sat: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("土曜勤務").required(),
      start: y.number().label("勤務開始時間（土）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（土）").required().min(0).max(47).moreThan(y.ref("sat.start"))
    })
  }),
  sun: y.object().when("type", {
    is: [ 2, 5 ],
    then: y.object().shape({
      work: y.boolean().label("日曜勤務").required(),
      start: y.number().label("勤務開始時間（日）").required().min(0).max(47),
      end: y.number().label("勤務終了時間（日）").required().min(0).max(47).moreThan(y.ref("sun.start"))
    })
  })
})

export const auroraDeliveryInportSchema = y.object().shape({
  id: y.string().label("予約ID").required().max(30),
  date: y.string().label("発送日").required().max(10).test(
    'is-valid-date',
    '正しい日付形式ではありません (YYYY/MM/DD) または存在しない日付です',
    value => isValidDate(value)
  ),
  delivery_type: y.string().label("配送方法").required().max(100),
  tracking_no: y.string().label("配送番号").required().max(100),
})
