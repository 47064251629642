import React, { FunctionComponent } from "react"
import { ReserveListDownLoad } from "./Reserve/reserveDownload"

export const ReservePage: FunctionComponent = () => {
  return (
    <>
      <ReserveListDownLoad />
    </>
  )
}
