import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core"
import { useStyles } from "../../styles/useStyle"
import CSVReader from "react-csv-reader"
import { auroraDeliveryInportSchema, medicalSchema } from "../../utils/validate"
import { DialogWithLoading } from "../Common/dialogWithLoading"
import { CSVView } from "../Common/csvView"
import { Result } from "../Common/result"
import { useFirestore } from "../../utils/firestore"
import { CSVLink } from "react-csv"
import { ExpandMore } from "@material-ui/icons"
import { useFirebaseFunction } from "../../utils/functions"
import {
  AuroraDeliveryExportCSVHeader,
  AuroraDeliveryInportCSVHeader,
  convertAuroraDeliveryInportCSVToJSON,
  convertAuroraDeliveryInportJsonToFB,
  getAuroraDeliveryListByCSV,
  IAuroraDeliveryInportCSV,
  IExportReserve,
  IRegisterDeliveryInportResponse, registerAuroraDeliveryInport,
  ReserveExportCSVHeader
} from "../../utils/reserveDao"
import moment from "moment"
import 'moment-timezone'

// タイムゾーンを東京に設定
moment.tz.setDefault("Asia/Tokyo")

export const AuroraDeliveryUpload : FunctionComponent = () => {
  const classes = useStyles()
  const [ errors, setErrors ] = useState<string[]>([])
  const [ csvData, setCSVData ] = useState<IAuroraDeliveryInportCSV[]>([])
  const [ result, setResult ] = useState<IRegisterDeliveryInportResponse[]>([])
  const [ show, setShow ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const firestore = useFirestore()

  const renderResult = useCallback(() => {
    return <Result result={result} />
  }, [ result ])

  const renderCSVData = useCallback(() => {
    let retVal = null
    if (!result.length) {
      retVal = <CSVView header={AuroraDeliveryInportCSVHeader} data={csvData} />
    }

    return retVal
  }, [ csvData, result ])

  const ok = useCallback(() => {
    setErrors([])
    setCSVData([])
    setResult([])
  }, [])

  const csvCancel = useCallback(() => {
    setErrors([])
    setCSVData([])
  }, [])

  const cancel = () => {
    setShow(false)
  }

  const confirm = () => {
    setShow(true)
  }

  const execute = async () => {
    setLoading(true)
    try {
      const promiseArray = csvData.map(data => convertAuroraDeliveryInportJsonToFB(data))
      const data = await Promise.all(promiseArray)
      const result = await registerAuroraDeliveryInport(firestore, data)
      setResult(result)
    } catch (e) {
      console.log(e)
      setResult([ {
        index: 0,
        result: "不明なエラーが発生しました。ページを更新して最初からやり直してください"
      } ])
    } finally {
      setShow(false)
      setLoading(false)
    }
  }

  const renderControlButton = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (csvData.length) {
        retVal = (
          <Box className={classes.verticalMargin}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className={classes.horizontalMargin} variant={"contained"} onClick={csvCancel}>キャンセル</Button>
              <Button variant={"contained"} color={"primary"} disabled={!!errors.length}
                      onClick={confirm}>取り込み実行</Button>
            </Box>
          </Box>
        )
      }
    } else {
      retVal = (
        <Box className={classes.verticalMargin}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className={classes.horizontalMargin} variant={"contained"} color={"primary"}
                    onClick={ok}>OK</Button>
          </Box>
        </Box>
      )
    }

    return retVal
  }, [ errors, csvData, result ])

  const renderErrorMessage = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (csvData.length) {
        let messages = null
        if (errors.length) {
          messages = errors.map((error, index) => <Typography key={`error_${index}`} variant={"body2"}
                                                              color={"error"}>{error}</Typography>)
        } else {
          messages = <Typography variant={"body2"} color={"primary"}>エラーはありませんでした</Typography>
        }
        retVal = (
          <Paper className={classes.paperContainer}>
            <Typography component={"h3"} variant={"h5"}>エラー情報</Typography>
            <Divider />
            <Box className={classes.wrapMargin}>
              {messages}
            </Box>
          </Paper>
        )
      }
    }

    return retVal
  }, [ csvData, errors, result ])

  const renderCSVImport = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (!csvData.length) {
        retVal = (
          <Paper className={classes.paperContainer}>
            <Typography component={"h3"} variant={"h5"}>オーロラ配送CSV取り込み</Typography>
            <Divider />
            <Box className={classes.wrapMargin}>
              <CSVReader
                fileEncoding={"Shift_JIS"}
                onFileLoaded={(data, _) => {
                  const promiseArray: Promise<{
                    errors: string[],
                    result: any
                  }>[] = []
                  data.forEach((value, index) => {
                    if (index !== 0 && value.some((cell: string) => cell.trim() !== "")) {
                      promiseArray.push(new Promise((resolve) => {
                        let errors: any[] = []
                        let converted: any = null

                        try {
                          if (value.length === AuroraDeliveryInportCSVHeader.length) {
                            converted = auroraDeliveryInportSchema.cast(convertAuroraDeliveryInportCSVToJSON(value))
                          } else {
                            errors.push(`${index + 1}行目：CSVが不正です。列数が一致していません`)
                          }
                        } catch (e) {
                          console.log(e)
                          errors.push(`${index + 1}行目：CSVが不正です`)
                        }
                        if (errors.length == 0) {
                          auroraDeliveryInportSchema.validate(converted).then((result) => {
                            resolve({
                              errors,
                              result
                            })
                          }).catch(e => {
                            errors = e.errors.map((text: string) => `${index + 1}行目：${text}`)
                            resolve({
                              errors,
                              result: null
                            })
                          })
                        } else {
                          resolve({
                            errors,
                            result: null
                          })
                        }
                      }))
                    }
                  })

                  Promise.all(promiseArray).then(values => {
                    let errors: string[] = []
                    let result: any[] = []

                    values.forEach(value => {
                      if (value.errors.length) {
                        errors = errors.concat(value.errors)
                      } else {
                        result.push(value.result)
                      }
                    })
                    setCSVData(result)
                    setErrors(errors)
                  })
                }}
              />
            </Box>
          </Paper>
        )
      }
    }

    return retVal
  }, [ csvData, result ])

  return (
    <>
      {renderCSVImport()}
      {renderErrorMessage()}
      {renderCSVData()}
      {renderResult()}
      {renderControlButton()}
      <DialogWithLoading
        title={"実行確認"}
        body={"CSVインポート処理を実行します。よろしいですか？"}
        show={show}
        loading={loading}
        onCancel={cancel}
        onExecute={execute}
      />
    </>
  )
}
